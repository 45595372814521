import Vue from 'vue'
import Vuex from 'vuex'
import dayjs from 'dayjs'
import instore from './instore'
import messaging from './messaging'
const axios = require('axios').default
axios.defaults.timeout = 5000
const {
  VUE_APP_API_URL
} = process.env

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentTime: '',
    dataLoaded: false,
    dataNotFound: false,
    loading: false,
    storeName: '',
    arrivalTime: '',
    orderNumber: '',
    currentState: '',
    parkingSlot: '',
    collectedTime: '',
    storeId: '',
    editMode: false,
    openingHours: [],
    clickAndParkDisabled: false,
    eta: '',
    location: '',
    calculatingETA: false,
    lastUpdateTime: ''
  },
  mutations: {
    currentTime: (state, value) => (state.currentTime = value),
    dataLoaded: (state, value) => (state.dataLoaded = value),
    dataNotFound: (state, value) => (state.dataNotFound = value),
    storeName: (state, value) => (state.storeName = value),
    arrivalTime: (state, value) => (state.arrivalTime = value),
    orderNumber: (state, value) => (state.orderNumber = value),
    currentState: (state, value) => (state.currentState = value),
    parkingSlot: (state, value) => (state.parkingSlot = value),
    loading: (state, value) => (state.loading = value),
    editMode: (state, value) => (state.editMode = value),
    collectedTime: (state, value) => (state.collectedTime = value),
    storeId: (state, value) => (state.storeId = value),
    openingHours: (state, value) => (state.openingHours = value),
    clickAndParkDisabled: (state, value) => (state.clickAndParkDisabled = value),
    eta: (state, value) => (state.eta = value),
    calculatingETA: (state, value) => (state.calculatingETA = value),
    location: (state, value) => (state.location = value),
    lastUpdateTime: (state, value) => (state.lastUpdateTime = value)
  },
  actions: {
    refreshData: ({ commit, state }, catsID) => axios.get(`${VUE_APP_API_URL}/cats/${catsID}`)
      .then(result => {
        const data = result.data
        if (data.storeTown) {
          commit('storeName', data.storeTown)
        } else {
          commit('storeName', 'Store')
        }

        if (data.arrivalTime) {
          const time = dayjs(data.arrivalTime)
          commit('arrivalTime', time)
        }
        commit('currentState', data.catsStatus)
        commit('parkingSlot', data.parkingSlot)
        commit('orderNumber', data.orderId)
        commit('storeId', data.storeId)
        if (data.collectionTime) {
          commit('collectedTime', dayjs(data.collectionTime))
        }
        commit('lastUpdateTime', data.lastUpdateTime)
        return 'ok'
      }),
    loadData: ({ commit, state }, catsID) => {
      commit('dataNotFound', false)
      return axios.get(`${VUE_APP_API_URL}/cats/${catsID}`)
        .then(result => {
          const data = result.data
          if (data.storeTown) {
            commit('storeName', data.storeTown)
          } else {
            commit('storeName', 'Store')
          }

          if (data.arrivalTime) {
            const time = dayjs(data.arrivalTime)
            commit('arrivalTime', time)
          }
          commit('currentState', data.catsStatus)
          commit('parkingSlot', data.parkingSlot)
          commit('orderNumber', data.orderId)
          commit('storeId', data.storeId)

          commit('location', data.location)
          if (data.eta) {
            commit('eta', data.eta)
            commit('calculatingETA', false)
          }

          if (data.collectionTime) {
            commit('collectedTime', dayjs(data.collectionTime))
          }
          commit('lastUpdateTime', data.lastUpdateTime)
          return 'ok'
        })
        .then(() => {
          // load store hours
          const storeId = state.storeId
          return axios.get(`${VUE_APP_API_URL}/cats/store/${storeId}`)
            .then(hours => {
              commit('openingHours', hours.data.openingHours)
              commit('clickAndParkDisabled', hours.data.clickAndParkDisabled || false)
            })
        })
        .then(() => commit('dataLoaded', true))
        .catch(err => {
          console.log(err)
          commit('dataNotFound', true)
        })
    },
    submitArrivalTime: ({ state, commit, dispatch }, { catsID, eta, location }) => {
      commit('loading', true)
      return axios.post(`${VUE_APP_API_URL}/cats/${catsID}`, {
        status: 'arrivalTimeSet',
        arrivalTime: state.arrivalTime,
        eta,
        location
      })
        .then(response => {
          commit('loading', false)
          return dispatch('loadData', catsID)
        })
        .catch(err => {
          console.log('Error updating arrival time', err)
          commit('loading', false)
        })
    },
    submitETA: ({ state, commit, dispatch }, { catsID, eta, location }) => {
      commit('loading', true)
      console.log('received location', location)
      return axios.post(`${VUE_APP_API_URL}/cats/${catsID}`, {
        status: 'customerArrived',
        eta,
        location: {
          latitude: location.latitude,
          longitude: location.longitude,
          accuracy: location.accuracy
        }
      })
        .then(response => {
          commit('loading', false)
          return dispatch('loadData', catsID)
        })
        .catch(err => {
          console.log('Error updating arrival time', err)
          commit('loading', false)
        })
    },
    customerArrived: ({ state, commit, dispatch }, catsID) => {
      commit('loading', true)
      // commit('arrivalTime', state.arrivalTime)
      return axios.post(`${VUE_APP_API_URL}/cats/${catsID}`, {
        status: 'customerArrived'
        // arrivalTime: state.arrivalTime
      })
        .then(response => {
          commit('loading', false)
          return dispatch('loadData', catsID)
        })
        .catch(err => {
          console.log('Error updating arrival time', err)
          commit('loading', false)
        })
    },
    clickAndPark: ({ state, commit, dispatch }, { catsID, parkingSlot, registrationNumber }) => {
      commit('loading', true)
      commit('arrivalTime', dayjs())
      return axios.post(`${VUE_APP_API_URL}/cats/${catsID}`, {
        status: 'clickAndParkRequested',
        arrivalTime: state.arrivalTime,
        parkingSlot,
        registrationNumber
      })
        .then(response => {
          commit('loading', false)
          return dispatch('loadData', catsID)
        })
        .catch(err => {
          console.log('Error updating arrival time', err)
          commit('loading', false)
        })
    },
    checkIfStoreIsOpen: ({ state }, storeId) =>
      axios.get(`${VUE_APP_API_URL}/cats/store/${storeId}/isopen`)
        .then(response => {
          return response.data.open
        })
        .catch(err => {
          console.log('Could not find out if the store is open...', err)
          return true
        })
  },
  modules: {
    instore,
    messaging
  }
})
