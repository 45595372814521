const state = () => ({
  messagingToken: '',
  alert: { },
  topicSubscriptions: []
})

const mutations = {
  messagingToken (state, value) {
    state.messagingToken = value
  },
  alert (state, value) {
    state.alert = value
  },
  topicSubscriptions (state, value) {
    state.topicSubscriptions = value
  }
}

const actions = {
  loadSubscriptions ({ commit }, deviceTokenID) {
    return ''
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
