import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)
dayjs.extend(duration)

const axios = require('axios').default
axios.defaults.timeout = 5000
const {
  VUE_APP_API_URL
} = process.env

const {
  stores
} = require('../../../resources/stores.json')

export default {
  namespaced: true,
  state: () => {
    return {
      stores: stores,
      entries: [],
      arrivedCATS: [],
      timeSetCATS: [],
      clickAndParkCATS: [],
      clickAndParkAccepted: [],
      clickAndParkRejected: [],
      loading: false,
      storeDetails: {},
      storeDetailsRefreshTime: '',
      regionDetails: [],
      regions: {},
      searchResults: [],
      smsSentCATS: [],
      storeId: '',
      storeName: '',
      storeLocations: { },
      userPreferences: {},
      userPrefsLoaded: false,
      pushSubscriptions: [],
      alertCustomerArrived: [],
      alertClickAndParkRequested: []
    }
  },
  mutations: {
    stores: (state, values) => (state.stores = values),
    entries: (state, values) => (state.entries = values),
    arrivedCATS: (state, values) => (state.arrivedCATS = values),
    timeSetCATS: (state, values) => (state.timeSetCATS = values),
    clickAndParkCATS: (state, values) => (state.clickAndParkCATS = values),
    clickAndParkAccepted: (state, values) => (state.clickAndParkAccepted = values),
    clickAndParkRejected: (state, values) => (state.clickAndParkRejected = values),
    smsSentCATS: (state, values) => (state.smsSentCATS = values),
    loading: (state, values) => (state.loading = values),
    storeDetails: (state, values) => (state.storeDetails = values),
    storeDetailsRefreshTime: (state, values) => (state.storeDetailsRefreshTime = values),
    regionDetails: (state, values) => (state.regionDetails = values),
    regions: (state, values) => (state.regions = values),
    searchResults: (state, values) => (state.searchResults = values),
    storeId: (state, values) => (state.storeId = values),
    storeName: (state, values) => (state.storeName = values),
    storeLocations: (state, values) => (state.storeLocations = values),
    pushSubscriptions: (state, values) => (state.pushSubscriptions = values),
    userPreferences: (state, value) => (state.userPreferences = value),
    userPrefsLoaded: (state, value) => (state.userPrefsLoaded = value),
    alertCustomerArrived: (state, value) => (state.alertCustomerArrived = value),
    alertClickAndParkRequested: (state, value) => (state.alertClickAndParkRequested = value)
  },
  actions: {
    setDefaultInLocalStorageIfNotSet: ({ commit }, { value, propertyName, localStorage }) => {
      if (typeof localStorage[propertyName] === 'undefined') {
        localStorage[propertyName] = value
        return true
      } else {
        return false
      }
    },
    loadStoreData: ({ commit }, { storeId, token }) => {
      return getEntiresForStore(storeId,
        ['customerArrived', 'arrivalTimeSet', 'clickAndParkRequested', 'clickAndParkAccepted', 'clickAndParkRejected'],
        commit,
        {
          customerArrived: 'arrivedCATS',
          arrivalTimeSet: 'timeSetCATS',
          clickAndParkRequested: 'clickAndParkCATS',
          clickAndParkAccepted: 'clickAndParkAccepted',
          clickAndParkRejected: 'clickAndParkRejected'
        },
        token)
    },
    loadAlertData: ({ commit }, { storeId, token }) => {
      return getEntiresForStore(storeId,
        ['customerArrived', 'clickAndParkRequested'],
        commit,
        {
          customerArrived: 'alertCustomerArrived',
          clickAndParkRequested: 'alertClickAndParkRequested'
        },
        token)
    },

    loadUncollectedData: ({ commit }, { storeId, token, states = ['arrivalTimeSet', 'smsSent'] }) => {
      return getEntiresForStore(storeId,
        states,
        commit,
        {
          smsSent: 'smsSentCATS',
          arrivalTimeSet: 'timeSetCATS'
        },
        token)
    },
    loadStoreDetails: ({ commit, state }, { storeId, token }) => {
      const now = dayjs()
      if (!state.storeDetailsRefreshTime || dayjs(now).diff(state.storeDetailsRefreshTime, 'seconds') > 10 || (state.storeDetails && state.storeDetails.storeId !== storeId)) {
        commit('storeDetailsRefreshTime', now)
        return getStoreDetails(storeId, token, commit, state)
      } else {
        return Promise.resolve()
      }
    },
    loadRegionDetails: ({ commit }, { storeIds, token }) => {
      // console.log('Store IDS: ', [...storeIds])
      return getRegionDetails([...storeIds], token, commit)
    },
    doSearch: ({ commit }, { token, storeId, orderNum }) =>
      axios.get(`${VUE_APP_API_URL}/admin/store/${storeId}/search`, {
        headers: {
          Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
        },
        params: {
          orderId: orderNum
        }
      })
        .then(result => {
          // console.log(result.data)

          commit('searchResults', result.data || [])
        })
        .catch(err => {
          console.log(err)
        }),
    loadRegionList: ({ commit }, { token }) =>
      axios.get(`${VUE_APP_API_URL}/admin/region-list`, {
        headers: {
          Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
        }
      })
        .then(result => {
          commit('regions', result.data)
        })
        .catch(err => {
          console.log(err)
        }),
    updateStatus: ({ commit, dispatch }, { storeId, catsID, status, collectedTime, token, parkingSlot }) => {
      commit('loading', true)
      return axios.post(`${VUE_APP_API_URL}/admin/cats/${catsID}`, {
        status: status,
        collectedTime: collectedTime,
        parkingSlot: parkingSlot
      }, {
        headers: {
          Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
        }
      })
        .then(response => {
          commit('loading', false)
          dispatch('loadStoreData', { storeId, token })
        })
        .catch(err => {
          console.log('Error updating status', err)
          commit('loading', false)
        })
    },
    loadUserPreferences: ({ commit }, token) => {
      return axios.get(`${VUE_APP_API_URL}/admin/preferences`, {
        headers: {
          Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
        }
      })
        .then(response => response.data)
        .then(response => {
          commit('userPrefsLoaded', true)
          if (response) {
            commit('userPreferences', response)
          }
        })
    },
    setUserPreference: ({ commit, state }, { token, prefName, prefValue }) => {
      return axios.put(`${VUE_APP_API_URL}/admin/preferences/${prefName}`, {
        value: prefValue
      }, {
        headers: {
          Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
        }
      })
        .then(() => {
          const userPrefs = state.userPreferences
          userPrefs[prefName] = prefValue
          commit('userPreferences', userPrefs)
        })
    },
    loadPushSubscriptions: ({ commit, state }, { token, deviceToken }) => {
      // console.log('got ', token, deviceToken)
      return axios.get(`${VUE_APP_API_URL}/admin/cats/push/${deviceToken}`, {
        headers: {
          Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
        }
      })
        .then((response) => {
          const subscriptions = response.data
          commit('pushSubscriptions', subscriptions.topics)
        })
    },
    removePushSubscription: ({ commit, state, dispatch }, { token, deviceToken, storeId }) => {
      // console.log('got ', token, deviceToken)
      return axios.delete(`${VUE_APP_API_URL}/admin/cats/push/${deviceToken}/${storeId}`, {
        headers: {
          Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
        }
      })
        .then(_ => dispatch('loadPushSubscriptions', { token, deviceToken }))
    },
    removeAllPushSubscriptions: ({ commit, state, dispatch }, { token, deviceToken }) => {
      // console.log('got ', token, deviceToken)
      return axios.delete(`${VUE_APP_API_URL}/admin/cats/push/${deviceToken}`, {
        headers: {
          Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
        }
      })
        .then(_ => dispatch('loadPushSubscriptions', { token, deviceToken }))
    },
    addPushSubscription: ({ dispatch, commit, state }, { token, deviceToken, storeId }) => {
      return axios.put(`${VUE_APP_API_URL}/admin/cats/push/${deviceToken}/${storeId}`, {}, {
        headers: {
          Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
        }
      })
        .then(response => console.log('Response received...', response))
        .then(() => dispatch('loadPushSubscriptions', { token, deviceToken }))
    }

  },
  modules: {
  }
}

const getEntiresForStore = (storeId, states, commit, commitMethodNameToStateMap, token) =>
  axios.get(`${VUE_APP_API_URL}/admin/store/${storeId}`, {
    params: {
      states: states
    },
    headers: {
      Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
    }
  })
    .then(result => {
      for (const state in result.data) {
        const commitLocation = commitMethodNameToStateMap[state]
        if (commitLocation) {
          commit(commitLocation, result.data[state])
        }
      }
      return result.data
    })
    .catch(err => {
      console.log(err)
    })

const getStoreDetails = (storeId, token, commit, state) =>
  axios.get(`${VUE_APP_API_URL}/admin/store/${storeId}/storeDetails`, {
    headers: {
      Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
    }
  })
    .then(result => {
      commit('storeDetails', result.data)
      return result.data
    })
    .then(data => {
      const locations = state.storeLocations
      if (!locations[storeId]) {
        return getLatLongForStore(data.storeId).then(location => {
          locations[storeId] = {
            lat: location.latitude,
            lng: location.longitude
          }
          console.log('Updated location', locations)
          commit('storeLocations', locations)
        })
      } else {
        return Promise.resolve()
      }
    })
    .catch(err => {
      console.log(err)
    })

const getLatLongForStore = (storeId) =>
  Promise.resolve(`https://hybris-services.external.wickes.co.uk/store-service/${storeId}`)
    .then(url => axios.get(url))
    .then(result => result.data)
    .then(data => data[0])
    .then(result => result.location)

const getRegionDetails = (storeIds, token, commit) =>
  axios.get(`${VUE_APP_API_URL}/admin/store/storeDetails/query`, {
    headers: {
      Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
    },
    params: {
      storeIds: storeIds
    }
  })
    .then(result => {
      commit('regionDetails', result.data)
    })
    .catch(err => {
      console.log(err)
    })
