<template>
  <v-app>
    <v-app-bar
      app
      clipped-left
      color="primary"
      dark
    >
      <v-app-bar-nav-icon
        v-if="$auth.isAuthenticated"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <div class="d-flex align-center">
        <v-img
          alt="Wickes Customer Arrival Service"
          class="shrink mx-2"
          contain
          src="https://unpkg.com/wickes-css@2.24.1/build/img/wickes-logo.svg"
          transition="scale-transition"
          width="85"
        />

        <h1 class="shrink mt-1 hidden-md-and-down">Arrival Tracker</h1>
      </div>

      <v-spacer></v-spacer>

      <notification-popup></notification-popup>
      <template v-if="$auth.isAuthenticated">
        <PushNotificationConfig>

        </PushNotificationConfig>
        <StoreOnlineIndicator></StoreOnlineIndicator>

        <template v-if="$vuetify.breakpoint.mdAndUp && $route.params.storeId">
          <v-btn
            class="mx-1"
            text
            v-shortkey="['d']"
            :to="`/${this.$route.params.storeId}/dashboard`"
            @shortkey="$router.push(`/${$route.params.storeId}/dashboard`)"
          >
            <span>Dashboard</span>
          </v-btn>
          <v-btn
            class="mx-1"
            text
            :to="`/${this.$route.params.storeId}/store-details`"
            v-shortkey="['s']"
            @shortkey="$router.push(`/${$route.params.storeId}/store-details`)"
          >
            <span>Store Details</span>
          </v-btn>
          <order-search-popup></order-search-popup>
        </template>

        <v-menu
          right
          bottom
        >
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              :to="'/store-selector'"
            >
              <v-list-item-icon>
                <v-icon>mdi-store</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                Store Selector
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list-item
            @click="$auth.logout"
          >
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              Log Out
            </v-list-item-content>
          </v-list-item>
        </v-menu>
      </template>
    </v-app-bar>

    <v-navigation-drawer
      v-if="$auth.isAuthenticated"
      v-model="drawer"
      app
      clipped
      color="grey lighten-4"
    >
      <v-list
        dense
        nav
      >
        <v-list-item>
          <v-list-item-content>
            <v-avatar><img :src="$auth.user.picture" /></v-avatar>
            <v-list-item-title>{{$auth.user.name}}</v-list-item-title>
            <v-list-item-subtitle>{{ storeName }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-group
          v-model="myStoreMenu"
        >
          <template v-slot:activator>
            <v-list-item-title>My Store</v-list-item-title>
          </template>

          <v-list-item
            v-if="defaultStore"
            :to="`/${defaultStore}/admin`"
          >
            <v-list-item-action>
              <v-icon>mdi-store</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Home
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="defaultStore"
            link
            :to="`/${defaultStore}/dashboard`"
          >
            <v-list-item-action>
              <v-icon>mdi-format-columns</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title >
                Arrivals Board
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="defaultStore"
            link
            :to="`/${defaultStore}/store-details`"
          >
            <v-list-item-action>
              <v-icon>mdi-gauge</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Store Details
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="defaultStore"
            link
            :to="`/${defaultStore}/consignments`"
          >
            <v-list-item-action>
              <v-icon>mdi-package</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title >
                Consignments
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            :to="'/store-selector'"
          >
            <v-list-item-action>
              <v-icon>mdi-store</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Change Store
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-divider></v-divider>

        <v-list-group
          v-model="regionalMenu"
        >
          <template v-slot:activator>
            <v-list-item-title>Regional Dashboards</v-list-item-title>
          </template>

          <v-list-item
            link
            to="/region-selector"
          >
            <v-list-item-action>
              <v-icon>mdi-earth</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title >
                Region Selector
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="defaultRegion"
            link
            :to="`/${defaultRegion}/region-details`"
          >
            <v-list-item-action>
              <v-icon>mdi-monitor-dashboard</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title >
                Region {{ defaultRegion }} Details
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-divider></v-divider>
        <v-list-group

          v-model="customRegionsMenu"
        >
          <template v-slot:activator>
            <v-list-item-title>Custom Regions</v-list-item-title>
          </template>
          <v-list-item
            link
            :to="`/custom-region-selector`"
          >
            <v-list-item-action>
              <v-icon>mdi-earth</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title >
                Custom Region Selector
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="region in Object.entries(customRegions)"
            link
            :to="`/region-details?storeIds=${region[1]}`"
            v-bind:key="region[0]"
          >
            <v-list-item-action>
              <v-icon>mdi-monitor-dashboard</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title >
                {{region[0]}}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                @click="deleteCustomRegion(region[0])"
                icon
                x-small
              >
                <v-icon color="grey lighten-1">mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list-group>
        <v-divider></v-divider>
        <preferences></preferences>
        <v-divider></v-divider>
        <v-list-item to='/whats-new'>
          <v-list-item-action>
            <v-icon>mdi-help-circle</v-icon>
          </v-list-item-action>
          <v-list-item-content><v-list-item-title>What's New</v-list-item-title></v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-list dense>
          <v-list-item
            link
            @click="$auth.logout"
          >
            <v-list-item-action>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="grey--text">
                Log Out
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

    </v-navigation-drawer>

    <v-main>
      <VersionUpdateNotification v-if="$auth.isAuthenticated"></VersionUpdateNotification>
      <router-view
        @openMenu="drawer = true"
        @defaultsUpdated="loadDefaults()"
        @saveCustomRegion="saveCustomRegion"
      ></router-view>
      <v-bottom-navigation
        fixed
        v-if="$vuetify.breakpoint.smAndDown && $route.params.storeId"
        app
      >
        <v-btn :to="`/${$route.params.storeId}/dashboard`">
          <span>Dashboard</span>
          <v-icon>mdi-format-columns</v-icon>
        </v-btn>
        <v-btn :to="`/${$route.params.storeId}/store-details`">
          <span>Store Details</span>
          <v-icon>mdi-gauge</v-icon>
        </v-btn>
        <order-search-popup :footer="true"></order-search-popup>
      </v-bottom-navigation>
    </v-main>

    <v-dialog
      v-model="confirmDelete"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">Confirm Delete?</v-card-title>

        <v-card-text>
          Are you sure you want to remove the region {{nameToDelete}}?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error darken-1"
            text
            @click="deleteCustomRegion(nameToDelete)"
          >
            Delete
          </v-btn>

          <v-btn
            color="primary darken-1"
            text
            @click="confirmDelete = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import Preferences from './views/Preferences'
import VersionUpdateNotification from '@/components/VersionUpdateNotification'
import NotificationPopup from '@/components/NotificationPopup'
import moment from 'moment'
import PushNotificationConfig from '@/components/PushNotificationConfig'
import StoreOnlineIndicator from '@/components/StoreOnlineIndicator'
import OrderSearchPopup from '@/components/OrderSearchPopup'

export default {
  name: 'App',
  components: { OrderSearchPopup, StoreOnlineIndicator, PushNotificationConfig, NotificationPopup, VersionUpdateNotification, Preferences },
  data: () => ({
    drawer: false,
    defaultStore: '',
    defaultRegion: '',
    customRegions: {},
    confirmDelete: false,
    nameToDelete: '',
    regionalMenu: false,
    customRegionsMenu: false,
    myStoreMenu: true
  }),
  computed: {
    ...mapState('instore', ['stores', 'regions', 'storeId']),
    storeName: function () {
      if (this.stores.length && this.defaultStore) {
        return this.stores.filter(item => item.storeId === this.defaultStore).map(item => item.storeName).join()
      } else {
        return this.defaultStore
      }
    },
    userEmail: function () {
      if (this.$auth && this.$auth.user) {
        return this.$auth.user.email
      } else {
        return 'anonymousUser'
      }
    }
  },
  watch: {
    '$route' (to, from) {
      document.title = to.meta.title || 'Wickes - CATS'
      const storeId = this.$route.params.storeId
      if (storeId) {
        const storeName = this.stores.length ? this.stores.filter(item => item.storeId === storeId).map(item => item.storeName).join() : 'No stores'
        this.$store.commit('instore/storeId', storeId)
        this.$store.commit('instore/storeName', storeName)
        console.log('setting', storeId, storeName)
      }
    },
    defaultStore (newStore) {
      localStorage.defaultStore = newStore
    },
    defaultRegion (newRegion) {
      localStorage.defaultRegion = newRegion
    },
    userEmail: function (newValue) {
      if (newValue) {
        this.$ddLogs.addLoggerGlobalContext('user-email', newValue)
      }
    }
  },
  methods: {
    loadDefaults: function () {
      if (localStorage.defaultStore) {
        this.defaultStore = localStorage.defaultStore
      }
      if (localStorage.defaultRegion) {
        this.defaultRegion = localStorage.defaultRegion
      }
      if (localStorage.customRegions) {
        this.customRegions = localStorage.customRegions ? JSON.parse(localStorage.customRegions) : {}
      }
    },
    saveCustomRegion: function ({ name, stores }) {
      const customRegions = this.customRegions || {}
      customRegions[name] = stores
      localStorage.customRegions = JSON.stringify(customRegions)
      this.loadDefaults()
    },
    deleteCustomRegion: function (regionName) {
      if (this.confirmDelete) {
        delete this.customRegions[regionName]
        localStorage.customRegions = JSON.stringify(this.customRegions)
        this.loadDefaults()
        this.confirmDelete = false
      } else {
        this.confirmDelete = true
        this.nameToDelete = regionName
      }
    },
    loadUserPrefs: function () {
      if (!this.$auth.loading) {
        if (this.$auth.isAuthenticated) {
          console.log('user logged in')
          this.$auth.getTokenSilently()
            .then(token => this.$store.dispatch('instore/loadUserPreferences', token))
        }
      } else {
        console.log('Authentication still in progress')
        setTimeout(this.loadUserPrefs, 1000)
      }
    },
    loadMessagingToken: function () {
      if (this.$messaging) {
        this.$messaging.getToken({ vapidKey: 'BHBMav2nZwizZYCMxQitRrzGxAVjLhymkJ3ITkkUR4rEAWMdDP78rHmdlii1afHE0tbeIKBl-GL15kT0h2Ykl8k' })
          .then(currentToken => {
            if (currentToken) {
              console.log('We have a token', currentToken)
              this.$store.commit('messaging/messagingToken', currentToken)

              this.$messaging.onMessage((message) => {
                console.log('got a message:', message)

                this.$store.commit('messaging/alert', message.notification)
                this.$auth.getTokenSilently()
                  .then(token => this.$store.dispatch('instore/loadStoreData', { storeId: this.storeId, token }))
              })
            } else {
              console.log('No token found, requesting.')
            }
          })
          .catch(err => {
            console.log('err requesting token', err)
          })
      } else {
        this.$ddLogs.logger.info('Messaging not initialised')
      }
    },
    startClock: function () {
      setInterval(() => this.$store.commit('currentTime', moment()), 1000)
    }
  },
  mounted () {
    this.loadDefaults()
    this.loadUserPrefs()
    this.loadMessagingToken()
    this.startClock()
  },
  beforeMount () {
    document.title = 'Wickes - CATS'
  }
}
</script>
