// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from 'firebase/compat/app'
// Add the Firebase services that you want to use
import 'firebase/compat/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyCDh8NITdVH8b0ZqkLw49EsmMIQaWdSjx4',
  authDomain: 'wx-ecom-cats-prod.firebaseapp.com',
  projectId: 'wx-ecom-cats-prod',
  storageBucket: 'wx-ecom-cats-prod.appspot.com',
  messagingSenderId: '225162842692',
  appId: '1:225162842692:web:6baa2a97f78d50e2866211',
  measurementId: 'G-K5ZV6DH4F3'
}

export default {
  install (Vue, options) {
    if (firebase.messaging.isSupported()) {
      firebase.initializeApp(firebaseConfig)
      Vue.prototype.$messaging = firebase.messaging()
    }
  }
}
