<template>
  <v-dialog
    v-model="showSearch"
    width="600"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="footer"
        v-on="on"
        @click="showSearch = true"
        v-shortkey="['ctrl', 'f']"
        @shortkey="showSearch = true"
      >
        <span>Search</span>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-btn
        v-else
        class="mx-1"
        text
        :x-small="$vuetify.breakpoint.xs"
        v-on="on"
        @click="showSearch = true"
        v-shortkey="['ctrl', 'f']"
        @shortkey="showSearch = true"
        icon
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </template>
    <v-card class="pb-4">
      <v-card-title>Order Search
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="showSearch = false"
        ><v-icon>mdi-close</v-icon></v-btn></v-card-title>
      <v-card-text>
        <v-autocomplete
          :items="searchResults"
          item-text="orderId"
          item-value="id"
          placeholder="Start entering an order number to search"
          :search-input.sync="search"
          :loading="searchLoading"
          v-model="searchedOrder"
          autofocus
          clearable
          return-object
        ></v-autocomplete>
      </v-card-text>
      <c-a-t-s-summary-view
        v-if="searchOrderDetails.id"
        :cat-item="searchOrderDetails"
      ></c-a-t-s-summary-view>
    </v-card>
  </v-dialog>
</template>

<script>
import debounce from 'lodash.debounce'
import { mapState } from 'vuex'
import CATSSummaryView from './CATSSummaryView'
export default {
  name: 'OrderSearchPopup',
  components: { CATSSummaryView },
  data () {
    return {
      searchedOrder: '',
      searchOrderDetails: {},
      searchLoading: false,
      search: '',
      showSearch: false
    }
  },
  props: {
    footer: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('instore', ['searchResults']),
    storeId: function () {
      return this.$route.params.storeId
    }
  },
  watch: {
    search (val) {
      if (!val) {
        return
      }
      this.debouncedSearch(val, this)
    },
    searchedOrder (order) {
      if (order) {
        return fetch(`${process.env.VUE_APP_API_URL}/cats/${order.id}`)
          .then(response => response.json())
          .then(data => {
            this.searchOrderDetails = data
          })
      }
    }
  },
  methods: {
    debouncedSearch: debounce(function (value, self) {
      this.doSearch(value, self)
    }, 250),
    doSearch: async (value, self) => {
      if (!value) {
        self.$store.commit('instore/searchResults', [])
      }

      if (self.searchLoading) {
        return
      }

      self.searchLoading = true
      const token = await self.$auth.getTokenSilently()
      self.$store.dispatch('instore/doSearch', {
        token: token,
        storeId: self.storeId,
        orderNum: value
      })
        .finally(() => (self.searchLoading = false))
    }
  }
}
</script>

<style scoped>

</style>
