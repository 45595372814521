<template>
  <v-container v-if="userPrefsLoaded && showAlert">
    <v-alert
      border="top"
      colored-border
      type="info"
      elevation="2"
    >
      Congratulations, your app has been updated to version {{ currentVersion }}. To see what's new, click the button to
      the right
      <div class="float-right">
        <WhatsNewPopup
          class="mx-2"
          :slide-data="slideData"
          :version-number="currentVersion">
        </WhatsNewPopup>
        <v-btn
          small
          icon
          @click="updateCurrentVersion"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

    </v-alert>

  </v-container>
</template>

<script>

import { mapState } from 'vuex'
import WhatsNewPopup from '@/components/WhatsNewPopup'
const whatsNew = require('../../public/whatsNew.json')

export default {
  name: 'VersionUpdateNotification',
  components: { WhatsNewPopup },
  data () {
    return {
      dismissed: false
    }
  },
  computed: {
    ...mapState('instore', ['userPreferences', 'userPrefsLoaded']),
    lastVersionSeen: function () {
      return this.userPreferences && this.userPreferences.lastVersionSeen ? this.userPreferences.lastVersionSeen : '0.0.0'
    },
    currentVersion: () => process.env.VUE_APP_VERSION || '1.0.0',
    slideData: function () {
      return (whatsNew && whatsNew[this.currentVersion])
        ? whatsNew[this.currentVersion]
        : [{
            id: '1',
            title: 'No major changes',
            description: 'This release just has bug fixes and tweaks to make the app work more effectively'
          }]
    },
    showAlert () {
      return !this.dismissed && this.lastVersionSeen !== this.currentVersion
    }
  },
  methods: {
    updateCurrentVersion: function () {
      return this.$auth.getTokenSilently().then(token => this.$store.dispatch('instore/setUserPreference', {
        token,
        prefName: 'lastVersionSeen',
        prefValue: this.currentVersion
      }))
        .then(() => {
          console.log('Updated version to ', this.currentVersion)
        })
        .then(() => (this.dismissed = true))
    }
  }
}
</script>

<style scoped>

</style>
