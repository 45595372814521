<template>
  <v-dialog
    v-model="showOverlay"
    width="600"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        class="mx-1"
        :x-small="$vuetify.breakpoint.xs"
        v-on="on"
        @click="showPopup"
        icon
      >
        <v-icon :color="alreadyRegistered ? 'green' : 'red'">mdi-bell</v-icon>
      </v-btn>
    </template>
    <v-card class="pb-4">
      <v-card-title>Notification Settings
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="showOverlay = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle>Browser Push Notification Permission: {{notificationStateText}}</v-card-subtitle>
      <v-card-subtitle v-if="notificationState === 'denied' || notificationState === 'unknown'">
        Notifications have been disabled on this device. This can only be re-enabled by updating the browser settings manually. <br>
        In Chrome, click on the padlock in the URL bar, then tap 'Site Settings', then choose the option to allow notifications. <br>
        Please refresh the browser after making this change.
      </v-card-subtitle>
      <template v-else>
        <v-card-subtitle>This browser is configured to receive push notifications for the following stores:</v-card-subtitle>
        <v-progress-linear indeterminate :active="loading" v-if="loading"></v-progress-linear>
        <v-card-text>
          <v-list>
            <template v-for="(subscription, index) in pushSubscriptions">
              <v-list-item
                :key="`push-item-${index}`"
              >
                <v-list-item-title>{{subscription}}</v-list-item-title>
                <v-list-item-action>
                  <v-btn @click="removeSubscription(subscription)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="`push-div-${index}`"></v-divider>
            </template>
            <v-list-item v-if="pushSubscriptions && pushSubscriptions.length === 0">
              No Push Subscriptions
            </v-list-item>
          </v-list>
        </v-card-text>
      </template>
      <v-card-actions>
        <v-row class="justify-space-around">
          <v-btn
            v-if="!alreadyRegistered"
            @click="addStoreId(storeId)"
            class="my-2"
          >
            Add {{ this.storeId }}
          </v-btn>
          <v-btn
            v-if="!alreadyRegistered && pushSubscriptions && pushSubscriptions.length > 0"
            @click="switchTo"
            class="my-2"
          >
            Switch to only {{ this.storeId }}
          </v-btn>
          <v-btn
            @click="removeAll"
            v-if="pushSubscriptions && pushSubscriptions.length > 0"
            class="my-2 warning"
          >
            Remove all
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PushNotificationConfig',
  data () {
    return {
      showOverlay: false,
      loading: false,
      notificationState: 'unknown'
    }
  },
  computed: {
    ...mapState('instore', ['storeId', 'pushSubscriptions']),
    ...mapState('messaging', ['messagingToken']),
    alreadyRegistered: function () {
      return this.pushSubscriptions && this.pushSubscriptions.length > 0 && this.pushSubscriptions.includes(this.storeId)
    },
    notificationStateText: function () {
      if (this.notificationState === 'denied') {
        return 'Disabled'
      } else if (this.notificationState === 'granted') {
        return 'Enabled'
      } else {
        return 'Disabled'
      }
    }
  },
  mounted () {
    this.getSubscriptions()
    this.refreshBrowserPermissions()
  },
  watch: {
    messagingToken: 'getSubscriptions'
  },
  methods: {
    getSubscriptions: function () {
      if (this.messagingToken && this.messagingToken.length > 0) {
        return this.$auth.getTokenSilently()
          .then(token => this.$store.dispatch('instore/loadPushSubscriptions', {
            token: token,
            deviceToken: this.messagingToken
          }))
          .then(() => this.signUpDefaultStoreIfNotSet())
      } else {
        this.$ddLogs.logger.info('No device token yet, so not requesting...', this.messagingToken)
        return Promise.resolve()
      }
    },
    signUpDefaultStoreIfNotSet: function () {
      if (localStorage.defaultStore && (!this.pushSubscriptions || this.pushSubscriptions.length === 0)) {
        // we need to register the default
        this.addStoreId(localStorage.defaultStore)
      }
    },
    showPopup: function () {
      this.loading = true
      this.showOverlay = !this.showOverlay
      this.refreshBrowserPermissions()
      this.getSubscriptions().finally(() => (this.loading = false))
    },
    addStoreId: function (storeId) {
      this.loading = true
      return this.$auth.getTokenSilently()
        .then(token => this.$store.dispatch('instore/addPushSubscription', {
          token,
          deviceToken: this.messagingToken,
          storeId: storeId
        }))
        .finally(() => (this.loading = false))
    },
    removeSubscription: function (storeId) {
      this.loading = true
      return this.$auth.getTokenSilently()
        .then(token => this.$store.dispatch('instore/removePushSubscription', {
          token,
          deviceToken: this.messagingToken,
          storeId
        }))
        .finally(() => (this.loading = false))
    },
    removeAll: function () {
      this.loading = true
      return this.$auth.getTokenSilently()
        .then(token => this.$store.dispatch('instore/removeAllPushSubscriptions', {
          token,
          deviceToken: this.messagingToken
        }))
        .finally(() => (this.loading = false))
    },
    switchTo: function () {
      return this.removeAll()
        .then(() => this.addStoreId(this.storeId))
    },
    refreshBrowserPermissions: function () {
      this.notificationState = Notification.permission
    },
    requestPermission: function () {
      Notification.requestPermission().then(function (permission) {
        console.log('Permission granted', permission)
      })
    }
  }
}
</script>

<style scoped>

</style>
