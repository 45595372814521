<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-chip
        class="mx-2"
        :color="storeDetails.onlineStatus ? 'green' : 'red'"
        v-on="on"
      >
        <v-avatar left>
          <v-icon v-if="storeDetails.onlineStatus">mdi-check</v-icon>
          <v-icon v-else>mdi-alert-circle</v-icon>
        </v-avatar>
        <span v-if="$vuetify.breakpoint.smAndUp">{{storeDetails.onlineStatus ? 'C&C Online' : 'C&C Offline'}}</span>
        <span v-else>{{storeDetails.onlineStatus ? 'On' : 'Off'}}</span>
      </v-chip>
    </template>
    {{ storeId }} is {{storeDetails.onlineStatus ? 'online' : 'offline'}} for C&C Orders
  </v-tooltip>

</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import dayjs from 'dayjs'

export default {
  name: 'StoreOnlineIndicator',
  data () {
    return {
      timer: ''
    }
  },

  computed: {
    ...mapState('instore', ['storeDetails', 'alertCustomerArrived', 'alertClickAndParkRequested']),
    storeId: function () {
      return this.$route.params.storeId || localStorage.defaultStore
    }
  },
  created () {
    this.fetchData()
    this.timer = setInterval(this.fetchData, 30000)
    this.fetchAlertData()
    this.timer = setInterval(this.fetchAlertData, 10000)
  },
  watch: {
    // call again the method if the route changes
    $route: 'fetchData',
    alertClickAndParkRequested: function (newData) {
      this.handleUpdates(newData, 'alertClickAndParkRequested', '/CarArrival.m4a', ' New Click & Park Arrivals')
    },
    alertCustomerArrived: function (newData) {
      this.handleUpdates(newData, 'alertCustomerArrived', '/Arrival.m4a', ' Customer on the way')
    }
  },
  methods: {
    fetchData: async function () {
      if (this.storeId) {
        this.error = null
        this.loading = true
        const token = await this.$auth.getTokenSilently()
        this.$store.dispatch('instore/loadStoreDetails', { storeId: this.storeId, token })
          .then(() => (this.loading = false))
          .catch(err => (this.error = err))
      }
    },
    fetchAlertData: async function () {
      if (this.storeId) {
        this.error = null
        this.loading = true
        const token = await this.$auth.getTokenSilently()
        this.$store.dispatch('instore/loadAlertData', { storeId: localStorage.defaultStore, token })
          .then(() => this.shouldPlayContinualAlerts())
          .catch(err => (this.error = err))
          .finally(() => (this.loading = false))
      }
    },
    shouldPlayContinualAlerts: function () {
      const playAudio = (localStorage.getItem('sounds') !== 'false')
      const waitingCars = this.alertClickAndParkRequested
        .filter(catItem => catItem.catsStatus === 'clickAndParkRequested')
        .filter(catItem => this.calculateCutOffTime(catItem) > 0)
      if (playAudio && waitingCars.length > 0) {
        // console.log('we have found one so play sound: ', waitingCars)
        new Audio('/CarArrival.m4a').play()
      } else {
        // console.log('Not playing as ', waitingCars, this.clickAndParkCATS)
      }
    },
    calculateCutOffTime: function (catItem) {
      // console.log('calculating cutoff for ', catItem)
      if (dayjs(catItem.lastUpdateTime).add(5, 'minutes').isBefore(dayjs())) {
        return 0
      }
      console.log('diff is', dayjs(catItem.lastUpdateTime).add(5, 'minutes').diff(this.currentTime))
      return dayjs(catItem.lastUpdateTime).add(5, 'minutes').diff(dayjs())
    },
    handleUpdates: function (newData, nameOfItems, alertFile, text = ' new updates have occurred') {
      const oldestUpdate = localStorage.getItem(`oldestUpdate-${nameOfItems}`) || 0
      const newlyAdded = newData
        .filter(item => moment(item.lastUpdateTime).unix() > oldestUpdate)
      const newestUpdate = newlyAdded
        .map(item => moment(item.lastUpdateTime).unix())
        .reduce((a, b) => Math.max(a, b), 0)

      const playAudio = (localStorage.getItem('sounds') !== 'false')
      // const showNotifications = (localStorage.getItem('notifications') !== 'false')
      if (newestUpdate > oldestUpdate) {
        if (newlyAdded.length > 0) {
          localStorage.setItem(`oldestUpdate-${nameOfItems}`, newestUpdate)
          if (playAudio) {
            const audio = new Audio(alertFile)
            audio.play()
          }
          // if (showNotifications) {
          //   this.$notification.show(newlyAdded.length + ' ' + text, {
          //     body: 'Click here to view in CATS'
          //   }, {})
          // }
        }
      } else {
        // console.log('No New updates, ', newestUpdate, oldestUpdate)
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.timer)
  }
}
</script>

<style scoped>

</style>
