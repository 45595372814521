import Vue from 'vue'
import dayjs from 'dayjs'
import dayOfYear from 'dayjs/plugin/dayOfYear'
import statuses from '../../../resources/statusMapping.json'

dayjs.extend(dayOfYear)

Vue.filter('shortTime', function (value) {
  if (!value) return ''
  const time = dayjs(value)
  if (time.dayOfYear() === dayjs().dayOfYear()) {
    return time.format('HH:mm')
  } else {
    return time.format('ddd HH:mm')
  }
})

Vue.filter('longDate', function (value) {
  if (!value) return ''
  return dayjs(value).format('HH:mm ddd D MMM')
})

Vue.filter('statusConverter', function (value) {
  const match = Object.entries(statuses).filter(item => item[1].db === value).map(item => item[1].display)
  if (match) {
    return match[0]
  } else {
    return `Unknown State(${value})`
  }
})
