<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item
        link
        v-on="on"
        v-bind="attrs"
      >
        <v-list-item-action>
          <v-icon>mdi-tools</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Preferences
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Settings</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="savePreferences">Save</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-list three-line subheader>
        <v-subheader>General</v-subheader>
        <v-list-item>
          <v-list-item-action>
            <v-checkbox
              :value="true"
              v-model="notifications"
            ></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Notifications</v-list-item-title>
            <v-list-item-subtitle>Show browser notifications about customer and Click & Park arrivals</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-checkbox
              :value="true"
              v-model="sound"
            ></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Sounds</v-list-item-title>
            <v-list-item-subtitle>Play alert sounds when customers arrive or park</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-btn
              v-if="messagingToken"
            >Disable Offline Push Notifications</v-btn>
            <v-btn
              v-else
            >Enable Offline Push Notifications</v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Preferences',
  data () {
    return {
      dialog: false,
      notifications: true,
      sound: true
    }
  },
  computed: {
    ...mapState('messaging', ['messagingToken'])
  },
  watch: {
    dialog: function () {
      this.reloadDefaults()
    }
  },
  methods: {
    savePreferences: function () {
      localStorage.setItem('sounds', (this.sound === true).toString())
      localStorage.setItem('notifications', (this.notifications === true).toString())
      this.dialog = false
    },
    reloadDefaults: function () {
      const notifications = localStorage.getItem('notifications')
      const sounds = localStorage.getItem('sounds')

      this.notifications = (notifications !== 'false')
      this.sound = (sounds !== 'false')
    }
  }
}
</script>

<style scoped>

</style>
