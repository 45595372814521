<template>
  <v-snackbar
    v-model="showNotification"
    timeout="10000"
    top
  >
    {{ alert.title }} {{ alert.body }}
    <template v-slot:action="{ attrs }">
      <v-btn
        v-if="alert && alert.click_action"
        color="green"
        text
        v-bind="attrs"
        :to="alert.click_action"
      >
        View
      </v-btn>
      <v-btn
        color="blue"
        text
        v-bind="attrs"
        @click="showNotification = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'NotificationPopup',
  data () {
    return {
      showNotification: false,
      title: '',
      body: ''
    }
  },
  computed: {
    ...mapState('messaging', ['messagingToken', 'alert'])
  },
  watch: {
    alert: function (previous, newValue) {
      console.log(previous, newValue)
      if (newValue) {
        this.showNotification = true
      }
    }
  }
}
</script>

<style scoped>

</style>
