<template>
    <v-btn
      small
      class="mx-2"
      color="primary"
      @click="showWhatsNew = true"
    >
      What's new
      <v-dialog
        width="500px"
        v-model="showWhatsNew"
      >
        <v-card>
          <v-card-title>What's new in Version {{ versionNumber }}</v-card-title>
          <v-carousel
            v-model="currentSlide"
          >
            <v-carousel-item
              :key='`slide-${slide.id}`'
              v-for="slide in slideData"
            >
              <v-img
                :src="slide.image || '/android-chrome-256x256.png'"
              ></v-img>
            </v-carousel-item>
          </v-carousel>
          <v-card-title>{{currentSlideData.title}}</v-card-title>
          <v-card-text>
            <vue-markdown
              :source="currentSlideData.description"
            >
            </vue-markdown>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-btn>

</template>

<script>
import VueMarkdown from 'vue-markdown/src/VueMarkdown'
export default {
  name: 'WhatsNewPopup',
  components: {
    VueMarkdown
  },
  data () {
    return {
      currentSlide: 0,
      showWhatsNew: false
    }
  },
  props: ['slideData', 'versionNumber'],
  computed: {
    currentSlideData () {
      return this.slideData[Number(this.currentSlide)]
    }
  }
}
</script>

<style scoped>

</style>
