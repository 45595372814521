import Vue from 'vue'
import VueRouter from 'vue-router'
import About from '../views/About'
import { authGuard } from '../auth/authGuard'
// import Home from '@/views/Home'
const customerSiteURL = process.env.VUE_APP_CUSTOMER_URL

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'CATS Home',
    component: About,
    meta: {
      title: 'Wickes - Customer Arrival Time System'
    }
  },
  // {
  //   path: '/t/:catsId',
  //   name: 'Arrival Time',
  //   component: Home,
  //   meta: {
  //     title: 'Wickes - Customer Arrival Time System'
  //   }
  // },
  {
    path: '/t/:catsId',
    redirect: to => {
      const { params } = to
      window.location.href = `${customerSiteURL}/t/${params.catsId}`
      return '/redirected'
    }
  },
  {
    path: '/store-selector',
    name: 'Store Selector',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "storeSelector" */ '../views/StoreSelector.vue'),
    beforeEnter: authGuard,
    meta: {
      title: 'Wickes - CATS Store Select'
    }
  },
  {
    path: '/store/:storeId',
    redirect: '/:storeId/dashboard'
  },
  {
    path: '/:storeId/dashboard',
    name: 'Store Arrivals Board',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "storeView" */ '../views/StoreView.vue'),
    beforeEnter: authGuard,
    meta: {
      title: 'Wickes - CATS Store Arrivals Board'
    }
  },
  {
    path: '/:storeId/customer-map',
    name: 'Customer Arrivals Map',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "storeView" */ '../views/CustomerMap.vue'),
    beforeEnter: authGuard,
    meta: {
      title: 'Wickes - CATS Store Arrivals Map'
    }
  },
  {
    path: '/:storeId/store-details',
    name: 'Store Details',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "storeDetailsView" */ '../views/StoreDetailsView.vue'),
    beforeEnter: authGuard,
    meta: {
      title: 'Wickes - CATS Store Details'
    }
  },
  {
    path: '/:storeId/admin',
    name: 'Admin Home Page',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "adminHome" */ '../views/AdminHome.vue'),
    beforeEnter: authGuard,
    meta: {
      title: 'Wickes - Admin Home Page'
    }
  },
  {
    path: '/:storeId/uncollected',
    name: 'Uncollected C&Cs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "uncollected" */ '../views/UncollectedDashboard.vue'),
    beforeEnter: authGuard,
    meta: {
      title: 'Wickes - CATS Uncollected C&Cs'
    }
  },
  {
    path: '/:storeId/consignments',
    name: 'All C&C Consignments',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "allConsignments" */ '../views/ConsignmentDashboard.vue'),
    beforeEnter: authGuard,
    meta: {
      title: 'Wickes - CATS C&C Consignment Dashboard'
    }
  },
  {
    path: '/region-selector',
    name: 'Region Selector',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "regionSelector" */ '../views/RegionSelector.vue'),
    beforeEnter: authGuard,
    meta: {
      title: 'Wickes - CATS Region Select'
    }
  },
  {
    path: '/custom-region-selector',
    name: 'CustomRegion Selector',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "regionSelector" */ '../views/FreeformRegionSelector.vue'),
    beforeEnter: authGuard,
    meta: {
      title: 'Wickes - CATS Custom Region Select'
    }
  },
  {
    path: '/region-details',
    name: 'Freeform Region Details',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "regionDetailsView" */ '../views/FreeformRegionDetailsView.vue'),
    beforeEnter: authGuard,
    meta: {
      title: 'Wickes - Region Details'
    }
  },
  {
    path: '/:regionId/region-details',
    name: 'Region Details',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "regionDetailsView" */ '../views/RegionDetailsView.vue'),
    beforeEnter: authGuard,
    meta: {
      title: 'Wickes - Region Details'
    }
  },
  {
    path: '/admin',
    name: 'Default Admin Home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "adminHome" */ '../views/AdminHome.vue'),
    beforeEnter: authGuard,
    meta: {
      title: 'Wickes - Admin Home'
    }
  },
  {
    path: '/whats-new',
    name: 'Application Updates',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "previousUpdates" */ '../views/PreviousUpdates.vue'),
    beforeEnter: authGuard,
    meta: {
      title: 'Wickes - Application Updates'
    }
  },
  {
    path: '/regional-customer-map',
    name: 'Regional Customer Map',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "previousUpdates" */ '../views/RegionalCustomerMap.vue'),
    beforeEnter: authGuard,
    meta: {
      title: 'Wickes - Regional Customer Map'
    }
  },
  {
    // will match everything
    path: '*',
    name: 'Page not found',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
    meta: {
      title: 'No CATS found'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
