import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/filters'
// Import the Auth0 configuration
import { domain, clientId, audience } from '../auth_config.json'
// Import the plugin here
import { Auth0Plugin } from './auth/index'

import VueShortKey from 'vue-shortkey'
import VueGtag from 'vue-gtag'
import VueNativeNotification from 'vue-native-notification'

import Firebase from '@/plugins/firebase'

import { datadogLogs } from '@datadog/browser-logs'

import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDi0i56W4f3H_QvtR50fdC71nAeF6jRsRc'
  }
})

export const DDLogsPlugin = {
  install (Vue, options) {
    Vue.prototype.$ddLogs = datadogLogs
    datadogLogs.init(options)
    datadogLogs.addLoggerGlobalContext('service', options.service)
    datadogLogs.addLoggerGlobalContext('environment', options.env)
    datadogLogs.addLoggerGlobalContext('version', options.version)
  }
}

Vue.use(DDLogsPlugin, {
  service: 'CATS',
  site: 'datadoghq.com',
  env: process.env.NODE_ENV,
  version: process.env.VUE_APP_VERSION,
  clientToken: 'pubc4776e914fbdc098ca5bb37dcd501907',
  forwardErrorsToLogs: true,
  sampleRate: 100
})

const { VUE_APP_GA_KEY } = process.env

Vue.use(VueGtag, {
  config: { id: VUE_APP_GA_KEY }
}, router)

Vue.use(Firebase)

Vue.use(VueNativeNotification, {
  // Automatic permission request before
  // showing notification (default: true)
  requestOnNotify: true
})

Vue.use(VueShortKey, { prevent: ['input', 'textarea'] })

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
