<template>
  <v-card>
    <v-toolbar
      color="primary"
      class="headline white--text"
      :dense="$vuetify.breakpoint.smAndDown"
    >
      <v-toolbar-title>
        {{catItem.orderId}} <span class="subtitle-2">({{catItem.id}})</span>
      </v-toolbar-title>
    </v-toolbar>

      <v-expansion-panels flat>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row>
              <v-col cols="6">Current State</v-col>
              <v-col cols="6">{{catItem.catsStatus | statusConverter}}</v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-timeline dense>
              <v-slide-x-transition
                group
              >
                <v-timeline-item
                  v-for="event in timeline"
                  :key="event.date.toString()"
                  class="mb-4"
                  color="primary"
                  small
                >
                  <v-row justify="space-between">
                    <v-col cols="7">{{event.status | statusConverter}}</v-col>
                    <v-col class="text-right" cols="5">{{event.date | longDate}}</v-col>
                  </v-row>
                </v-timeline-item>
                <v-timeline-item
                  v-if="catItem.orderDetails && catItem.orderDetails.creationTime"
                  key="created-entry"
                  class="mb-4"
                  color="green"
                  small
                >
                  <v-row justify="space-between">
                    <v-col cols="7">Created</v-col>
                    <v-col class="text-right" cols="5">{{catItem.orderDetails.creationTime | longDate}}</v-col>
                  </v-row>
                </v-timeline-item>
              </v-slide-x-transition>
            </v-timeline>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-divider></v-divider>
      <v-simple-table dense>
        <template v-slot:default>
          <tbody>
          <tr v-if="catItem.orderDetails && catItem.orderDetails.creationTime">
            <td>Order Placed</td>
            <td>{{catItem.orderDetails.creationTime | longDate}}</td>
          </tr>
            <tr v-if="catItem.customerName">
              <td>Customer Name</td>
              <td>{{catItem.customerName}}</td>
            </tr>
            <tr v-if="catItem.registrationNumber">
              <td>Car Registration</td>
              <td class="text-uppercase">{{catItem.registrationNumber}}</td>
            </tr>
            <tr v-if="catItem.eta">
              <td>ETA</td>
              <td>{{catItem.eta | longDate}}</td>
            </tr>
            <tr v-if="catItem.date">
              <td>Order Picked</td>
              <td>{{catItem.date | longDate}}</td>
            </tr>
            <tr v-if="catItem.number">
              <td>Customer Phone</td>
              <td>
                <v-btn :href="`tel:${catItem.number}`"
                       icon
                       color="secondary"
                >
                  <v-icon>mdi-phone</v-icon>
                </v-btn>
                {{catItem.number}}
              </td>
            </tr>
            <tr v-if="catItem.arrivalTime">
              <td>Scheduled Arrival Time</td>
              <td>{{catItem.arrivalTime | longDate}}</td>
            </tr>
            <tr v-if="catItem.lastUpdateTime">
              <td>Last Update</td>
              <td>{{catItem.lastUpdateTime | longDate}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer />
      <v-btn
        class="warning"
        @click="hidden(catItem.id)"
      >Hide</v-btn>
      <!--                    <v-btn>Edit</v-btn>-->
      <v-btn
        class="secondary"
        @click="collected(catItem.id)"
      >Collected</v-btn>
    </v-card-actions>

  </v-card>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'CATSSummaryView',
  props: ['catItem'],
  methods: {
    collected: async function (id) {
      const token = await this.$auth.getTokenSilently()
      this.$store.dispatch('instore/updateStatus', {
        storeId: this.$route.params.storeId,
        catsID: id,
        status: 'collected',
        collectedTime: dayjs().format(),
        token: token
      }).then(() => {
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.error = err
        this.loading = false
      })
    },
    hidden: async function (id) {
      const token = await this.$auth.getTokenSilently()
      this.$store.dispatch('instore/updateStatus', {
        storeId: this.$route.params.storeId,
        catsID: id,
        status: 'hidden',
        token: token
      }).then(() => {
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.error = err
        this.loading = false
      })
    }
  },
  computed: {
    timeline: function () {
      if (this.catItem && this.catItem.statusUpdates) {
        const sorted = JSON.parse(JSON.stringify(this.catItem.statusUpdates))
          .map(item => {
            item.date = dayjs(item.date)
            return item
          })
        return sorted.sort((item1, item2) => item2.date.diff(item1.date))
      } else {
        return []
      }
    }
  }
}
</script>

<style scoped>

</style>
